import DashboardLogo from "../images/dashboardlogo.svg";
import { useEffect, useState } from "react";
import EditGraphic from "../images/editingtoggle.svg";
import EditPlus from "../images/SourcesPlus.svg";
import "../css/dashboard.css";

const TextEntry = function (props) {
  const {name,type, set, value} = props;

  const ele = type === "input" ? 
    <input value={value} onChange={(e)=>{set(e.target.value)}} />
     : 
    <textarea value={value} onChange={(e)=>{set(e.target.value)}}/>;
  return (
  <div className="input">
    <p className="input-header">{name}</p>
    {ele}
  </div>)
}

const SourcesContainer = function(props) {
  const {sources,setSources,setIsAddingSource} = props;
  let classes = "sources-container empty"
  let onClick = ()=>setIsAddingSource(true);
  let element = (
    <>
      <img src={EditPlus} />
      <p>Add a Source</p>
    </>
  )
  if(sources.length > 0){
    onClick = () => {}
    classes = "sources-container"
    element = sources.map((x)=>{
      return (
        <div className="source">
          {sources.indexOf(x) + 1}: {x.name.substring(0,20)} 
        </div>
      )
    })
  
  }
  return (
  <div className={classes} onClick={onClick}>
    {element}
  </div>);
}

const Button = function(props) {
  let {inactive,color,text,click} = props;
  let classstr = `button ${color}`
  if(inactive){
    classstr = "button inactive";
    click = () => {}
  }
  return (
    <div className={classstr} onClick={()=>{click()}}>
      {text}
    </div>
  );
}

const TWITCH_REGEX = /(?:https:\/\/www.twitch.tv\/((?:([\w\d]+\/clip))|videos)\/([\d\w_-]+)(\?.+)*)|(https:\/\/clips.twitch.tv\/)([\d\w_-]+)/

const Overlay = function(props) {
  const {setIsAddingSource,sources,setSources} = props;
  const [url,setUrl] = useState("");
  const [start,setStart] = useState("");
  const [end,setEnd] = useState("");
  const [name,setName] = useState("");
  const [clip, setClip] = useState("");

  const resetState = () => {
    let funcs = [setUrl,setStart,setEnd,setName,setClip];
    for(let f of funcs){
      f("");
    }
  }
  let disableSubmit = false;
  if(clip){
    if(clip.isVod){
      const TIMESTAMP_REGEX = /^(?:(\d{2}):(\d{2}):(\d{2}))$/
      disableSubmit = !TIMESTAMP_REGEX.test(start) || !TIMESTAMP_REGEX.test(end);
      let start_matches = TIMESTAMP_REGEX.exec(start);
      let end_matches = TIMESTAMP_REGEX.exec(end);
      if(start_matches && end_matches){
        let start_seconds = parseInt(start_matches[1]) * 3600 + parseInt(start_matches[2]) * 60 + parseInt(start_matches[3]);
        let end_seconds = parseInt(end_matches[1]) * 3600 + parseInt(end_matches[2]) * 60 + parseInt(end_matches[3]);
        disableSubmit = (
          start_seconds >= end_seconds || start_seconds < 0 || end_seconds < 0 ||
          end_seconds > clip.duration || end_seconds - start_seconds > 45 * 60 ||
          parseInt(start_matches[2]) > 59 || parseInt(end_matches[2]) > 59 ||
          parseInt(start_matches[3]) > 59 || parseInt(end_matches[3]) > 59
        ); 
      } else {
        disableSubmit = true;
      }
    } else {
      disableSubmit = start > end || start < 0 || end < 0 || end > clip.duration || start > clip.duration || isNaN(start) || isNaN(end); 
    }
  }
  useEffect(()=>{
    async function getClip(url){
      let raw = await fetch(`./videoInfo?url=${url}`)
      let res = await raw.json();
      if(res.success){
        setClip(res);
        setStart(res.isVod ? formatTime(0) : 0);
        if(res.isVod){
          if(res.duration > 45 * 60){
            setEnd(formatTime(45 * 60 * 1000));
          } else {
            setEnd(formatTime(res.duration * 1000));
          }
        } else {
          setEnd(res.duration);
        }
      } else {
        setClip(null);
      }
    }
    if(url && TWITCH_REGEX.test(url)){
      getClip(url);
    } else {
      setClip(null);
    }
  },[url]);


  return (
    <div className="overlay">
      <div className="overlay-body">
        <TextEntry value={url} name="Clip / VOD URL *" set={setUrl} type="input" />
        {clip && 
          <>
            <div className="overlay-splitter">
              <div className="item">
                <TextEntry value={start} name="Start Time *" set={setStart} type="input"/>  
              </div>
              <div className="item">
                <TextEntry value={end} name="End Time *" set={setEnd} type="input"/>  
              </div> 
            </div>
            <TextEntry value={name} name="Name" set={setName} type="input" />
            
          </>
        }
        <div className="overlay-splitter">
              {
                clip &&
                <div className="item">
                  <Button inactive={disableSubmit} text="Add" color={"green"} click={()=> {
                    const new_sources = [...sources,{
                      type:clip.isVod ? "vod" : "clip",
                      url,
                      start: `${start}`,
                      end:`${end}`,
                      name
                    }];
                    setSources(new_sources)
                    setIsAddingSource(false);
                    resetState();
                  }}/>
                </div>
              }
              <div className="item">
                <Button text="Cancel" color={"red"} click={()=>{
                  resetState();
                  setIsAddingSource(false)
                }}/>
              </div>
            </div>
      </div>
    </div>
  );
}

const createJob = function(title,description,tags,sources){
  return {
    name:title,
    description,
    tags,
    clips:sources
  }
}

const Tag = function(props) {
  const selected_tag = props.tags[props.index];
  function removeTag(){
    const new_tags = [...props.tags];
    new_tags.splice(props.index,1);
    props.setTags(new_tags);
  }
  function updateTagValue(value){
    const new_tags = [...props.tags];
    new_tags[props.index] = value;
    props.setTags(new_tags);
  }
  return (
    <div className="tag">
      <img src={EditPlus} onClick={removeTag}></img>
      {
        <input
        value={selected_tag}
        onChange={
          (e)=>updateTagValue(e.target.value)
        } />
      }
    </div>);
}

const EditingPage = function (props) {
  const {setIsEditing} = props;
  const [sources, setSources] = useState([]);
  const [isAddingSource,setIsAddingSource] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags,setTags] = useState([]);
  return (
    <>
      { isAddingSource
        ? 
          <Overlay 
            setIsAddingSource={setIsAddingSource} 
            sources={sources}
            setSources={setSources}
          /> : <div /> 
        }
      <TextEntry name="Title" type="input" set={setTitle} />
      
      <div className="tag-container">
        <p className="tag-header input-header"
          onClick={
            ()=>{setTags([...tags,`tag${tags.length}`])
          }}>
            Tags <img src={EditPlus} />
        </p>
        {
          tags.map((x,i) => <Tag tags={tags} setTags={setTags} index={i} />)
        }
      </div>
      <div className="lower-container">
        <div className="left">
          <TextEntry name="Description" set={setDescription} />
        </div>
        <div className="right">
          <p className="input-header sources-header">Sources <span onClick={()=>setIsAddingSource(true)}>+</span></p>
          <SourcesContainer sources={sources}  setSources={setSources} setIsAddingSource={setIsAddingSource}/>          
        </div>
      </div>
      <div className="upload-container">
        <Button name="upload" inactive={sources.length === 0} text="Upload" color={"green"} click={async () => {
          let raw = await fetch("./jobs",{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(createJob(title,description,tags,sources))
          })
          let res = raw.json();
          setIsEditing(false);
        }}/>
      </div>
    </>
  );
}

const EditingToggle = function (props) {
  const {isEditing, setIsEditing} = props;
  let className = "editing-toggle";
  if(isEditing){
    className = "editing-toggle active";
  }
  return (
    <img
      src={EditGraphic}
      className={className}
      onClick={()=>{
        setIsEditing(!isEditing)}
      } />
  );
}

// takes in a int representing a duration in milliseconds, and formats it into HH:MM:SS
const formatTime = function(time){
  let hours = Math.floor(time / 3600000);
  time = time - hours * 3600000;
  let minutes = Math.floor(time / 60000);
  time = time - minutes * 60000;
  let seconds = Math.floor(time / 1000);
  if(hours < 10){
    hours = `0${hours}`;
  }
  if(minutes < 10){
    minutes = `0${minutes}`;
  }
  if(seconds < 10){
    seconds = `0${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
}

const Duration = function (props){
  const duration = props.time;
  if(duration < 0){
    return <span />
  }
  const time = formatTime(duration);
  return (
    <>
      <span className="dark-grey">{" "}|{" "}</span> 
      {time}
    </>
  )
}

const Status = function(props) {
  const {assigned,complete} = props;
  let text= "";
  if(!assigned){
    text = "PREPARING";
  }
  if(assigned && !complete){
    text = "RENDERING"
  }
  if(assigned && complete){
    text = "COMPLETE"
  }
  return (
    <>
      <span className="dark-grey">{" "}|{" "}</span>
      {text}
    </>
  )
}

const Job = function(props) {
    const job = props.job;
    let duration_in_milliseconds = job.duration;
    return (
        <div className="job">
            <p>
                {job.config.name.substring(0,55)}
                <span className="job-misc-info">
                  <span className="dark-grey">{" "}|{" "}</span>
                  <span className="cream">{job.config.clips.length} clip{job.config.clips.length>1 ? "s":""}</span>
                  <Duration time={duration_in_milliseconds} />
                  <Status assigned={job.job_assigned} complete={job.job_complete}/>          
                </span>
                {
                  job.youtube_url !== "" && 
                  <>
                    <span className="dark-grey">{" "}|{" "}</span>
                    <a className="cream" href={job.youtube_url}>LINK</a>
                  </>
                }    
            </p>
            {job.storage_url !== "" && <a className="download" href={job.storage_url}>Download</a>}
        </div>
    )
}

const JobsDisplay = function (props) {
  const {jobs} = props;
  if (jobs === undefined || jobs.length === 0){
    return (
      <div className="jobs-display">
        <div className="no-jobs">
          No videos yet created! Create a video using the plus in the top right corner
        </div>
      </div>);
  }
  return (
    <div className="jobs-display">
      {[...jobs].reverse().map((x)=>{
        return <Job job={x} />
      })}
    </div>
  );
}

const Dashboard = function () {
  const [isEditing,setIsEditing] = useState(false);
  const [user,setUser] = useState("");
  useEffect(()=>{
    async function getUserData(){
      let raw = await fetch("./user");
      let res = await raw.json();
      return res;
    }
    getUserData().then((res)=>{
      setUser(res)
    })
  },[isEditing])
  let balance_dollars = Math.floor(user.balance / 100) || 0;
  let balance_cents = user.balance % 100 || 0;
  let padded_cents = balance_cents < 10 ? "0" + balance_cents : balance_cents; 
  return (
    <div className="dashboard">
      <div className="navbar">
        <img className="logo" src={DashboardLogo}></img>
        <div className="balance">
          <p className="balance-text">{user.free} Free</p>
        </div>
        <div className="balance">
          <p className="balance-text">{balance_dollars}.{padded_cents}</p>
        </div>
        <a href="./payment">
          <div className="balance">
            <p className="balance-text">Add $</p>
          </div>
        </a>
        <EditingToggle isEditing={isEditing} setIsEditing={setIsEditing} />
        <img className="user-icon" src={user.icon} />
      </div>
      <div className="content">
        {isEditing ? <EditingPage setIsEditing={setIsEditing} /> : <JobsDisplay jobs={user.jobs}/>}
      </div>
    </div>
  )
}

export default Dashboard;
