import {useNavigate} from "react-router-dom"
import '../css/lander.css'
import LanderText from "../images/StitchLanderText.svg"
import Graphic from "../images/largegraphic.png";
import MobileGraphic from "../images/mobilelargegraphic.png"
import DownArrow from "../images/downarrow.png";
console.log(document.cookie);

function App() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <div className="lander">
        <p className="youtube-login top-right" onClick={() => window.location.replace("./login")}>Login With Youtube</p>
        <img className="down-arrow" src={DownArrow} />
        <img className="logo-mb" src={LanderText} />
        <div className="left-side">
          <img className="graphic-mb" src={MobileGraphic} />
          <img className="logo-dt" src={LanderText} />
          <p className="header-subtext">Less editing, more doing</p>
          <button className="button button-green" onClick={() => window.location.replace("./login")}>
            <p className="button-text">Try for free!</p>
          </button>
        </div>
        <div className="right-side">
          <img className="graphic-dt" src={Graphic} />
        </div>
      </div>
      <h1>
        <p>Using Stitch to make content</p>
      </h1>
      <p className="text"> 
        Stitch is a simple video editor that allows you to source content from Twitch. This means that you can combine one or more Twitch clips / vods into one great video. Are you a streamer trying to create a compilation? Upload a single clip? Edit a stream into a funny video? Stitch makes it simple and direct. 
      </p>
      <h1>
        <p>Pricing</p>
      </h1>
      <p className="text"> 
        2 free videos each week, with watermark. Max of 10 clips per video. 10 cents per video for no watermark, vod video sources, 2 hour max length.  
      </p>
        <p className="youtube-login youtube-login-large" onClick={() => window.location.replace("./login")}>Login With Youtube</p>
    </div>
  );
}



export default App;
